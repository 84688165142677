import ObjectUtils from "@/utils/object-utils";
import parseISO from "date-fns/parseISO";
import {i18n} from "@/bootstraps/locale";

export default class AuditEntity {
  public id!:number;
  public event!:AuditEventType;
  public userName!:string|null;
  public date!:Date;
  public option1!:string | null;
  public option2!:string | null;

  constructor(init:Partial<AuditEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
    this.date = parseISO(init.date as any);
  }

  public get actionLabel(): string {
    switch (this.event) {
      case AuditEventType.created: return i18n.t('作成');
      case AuditEventType.updated: return i18n.t('更新');
      case AuditEventType.deleted: return i18n.t('削除');
      case AuditEventType.accessed: return i18n.t('閲覧');
      case AuditEventType.published: return i18n.t('正規の版として保存');
      case AuditEventType.accepted: return i18n.t('受領');
      case AuditEventType.approvalStarted: return i18n.t('承認ワークフロー「{option1}」を開始', {option1: this.option1});
      case AuditEventType.approvalRequestCanceled: return i18n.t('承認ワークフロー「{option1}」を取り下げ', {option1: this.option1});
      case AuditEventType.approvalApproved: return i18n.t('承認ワークフロー「{option1}（{option2}）」において承認', {option1: this.option1, option2: this.option2});
      case AuditEventType.approvalRevoked: return i18n.t('承認ワークフロー「{option1}」の承認をキャンセル', {option1: this.option1});
      case AuditEventType.specReportExported: return i18n.t('「{option2}」を出力', {option2: this.option2});

      case AuditEventType.locked: return i18n.t('ロック');
      case AuditEventType.unlocked: return i18n.t('ロック解除');
      default: return '';
    }
  }
}

export enum AuditEventType {
  created = 'created',
  updated = 'updated',
  deleted = 'deleted',
  accessed = 'accessed',
  published = 'published',
  accepted = 'accepted',
  approvalStarted = 'approval_started',
  approvalRequestCanceled = 'approval_request_canceled',
  approvalApproved = 'approval_approved',
  approvalRevoked = 'approval_revoked',
  specReportExported = 'spec_report_exported',

  locked = 'locked',
  unlocked = 'unlocked',
}
