import ObjectUtils from "@/utils/object-utils";
import IFile from "@/contracts/i-file";

export default class AttachmentEntity implements IFile{
  public static readonly ACCEPT_MIME_LIST = [
    'image/png', 'image/gif', 'image/jpeg', 'image/bmp', 'image/webp',
    'application/pdf', 'text/plain', 'application/zip',
    'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ];

  public key:string|null = null;
  public filename!:string;
  public body:string|null = null;
  public url?:string;

  public isLoading = false;
  public __destroy:boolean = false;

  constructor(init:Partial<AttachmentEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }

  public get isImage() {
    return this.body && this.body.startsWith('data:image');
  }

  public get isViewableAsIFrame() {
    if (!this.body) return false;
    return this.body.startsWith('data:application/pdf') || this.body!.startsWith('data:text/plain');
  }

  public get isInvisibleFile() {
    return !['png', 'gif', 'jpeg', 'jpg', 'bmp', 'webp', 'pdf', 'txt'].includes(this.extension);
  }

  public get isAdded() {
    return !this.key;
  }

  private get extension(): string {
    return this.filename.split('.').pop() || '';
  }
}

