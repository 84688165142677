import {
  createDecimalRule,
  createMaxRule,
  createMaxStringRule,
  createMinRule,
  requiredRule,
  typeNumberOrEmptyRule
} from '@/utils/validation-rules';

import AllergyEntity from "@/entities/allergy-entity";

import ObjectUtils from "@/utils/object-utils";

import IngredientMadeInAreaEntity, {ValidatorRules as IngredientMadeInAreaEntityValidationRules} from "@/entities/ingredient-made-in-area-entity";
import IngredientAdditiveEntity, {ValidatorRules as IngredientAdditiveValidationRules} from "@/entities/ingredient-additive-entity";
import IngredientItemEntity, {
  IAmountItem,
  IngredientItemValidatorRules
} from "@/entities/ingredient-item-entity";

import IngredientDisplaySetting from "@/entities/ingredient-display-setting";

import parseISO from 'date-fns/parseISO';
import sumBy from "lodash/sumBy";
import IngredientNutritionEntity from "@/entities/ingredient-nutrition-entity";
import AttachmentEntity from "@/entities/attachment-entity";
import CarryOver from "@/entities/carryover-entity";
import UserEntity from "@/entities/user-entity";
import {NutritionValue} from "@/entities/nutrition-value";
import IngredientSpecReferenceEntity from "@/entities/ingredient-spec-reference-entity";
import {FoodType} from "@/entities/specs/spec-entity";
import CompanyDepartmentEntity from "@/entities/company-department-entity";
import {IProductMixin} from "@/entities/concerns/i-product-mixin";
import sum from "lodash/sum";
import {IngredientWithAmount} from "@/entities/concerns/ingredient-with-amount";
import {IngredientWithAmountCollection} from "@/entities/concerns/ingredient-with-amount-collection";
import IngredientItemGmoEntity, {GmoEntity} from "@/entities/ingredient-item-gmo-entity";
import {IHasParent, IRecipeComponent, IRecipeComponentItem} from "@/entities/interfaces/i-recipe-component";

export default class IngredientEntity implements IRecipeComponent {
  public static readonly KEY_PREFIX = "ing-";
  public static readonly KEY_PREFIX_PREPRODUCT = "pre-";

  public id!:number;
  public companyOrd!:number;
  public companyId!:number;
  public isPreproduct:boolean = false;
  public type: FoodType = FoodType.Fresh;
  public name!:string;
  public intraCode:string|null = null;
  public displayName:string = '';
  public costPerKg:number|null = null;

  public unit:string|null = null;
  public amountPerUnit:number|null = null;
  public yieldPercent:number = 100;
  public isYieldAffectToNutrition:boolean = false; // 歩留まり率
  public isYieldAsConcentrationReduction:boolean = false; // 濃縮・水戻しとして扱う
  public isNutritionOverrideOnPreproduct:boolean = false;

  public locked:boolean = false;
  public note:string = "";
  public createdAt!:Date;
  public updatedAt!:Date;
  public createdByUser!:UserEntity;
  public updatedByUser!:UserEntity;

  public ingredientMadeInAreas:IngredientMadeInAreaEntity[] = [];
  public ingredientAdditives:IngredientAdditiveEntity[] = [];

  public formulationAdditiveSynonymId: number|null = null;

  public ingredientItems:IngredientItemEntity[] = [];
  public parentItem:IRecipeComponentItem|null = null;

  public nutrition!:IngredientNutritionEntity;
  public attachments:AttachmentEntity[] = [];

  public ingredientDisplaySetting!:IngredientDisplaySetting;

  public specReference: IngredientSpecReferenceEntity | null = null;

  public tags: string[] = [];

  public departments: CompanyDepartmentEntity[] = [];
  public departmentIds: number[] = [];

  // View用
  public isYieldEnabled!:boolean;
  public original_hasUnit!:boolean;

  constructor(init:Partial<IngredientEntity> = {}, parentItem:IRecipeComponentItem|null = null) {
    ObjectUtils.assignLiteralFields(this, init);

    this.parentItem = parentItem;

    if (init.createdAt) {
      this.createdAt = parseISO((init as any).createdAt);
    }
    if (init.updatedAt) {
      this.updatedAt = parseISO((init as any).updatedAt);
    }
    if (init.createdByUser) {
      this.createdByUser = new UserEntity(init.createdByUser);
    }
    if (init.updatedByUser) {
      this.updatedByUser = new UserEntity(init.updatedByUser);
    }

    if (init.ingredientMadeInAreas) {
      this.ingredientMadeInAreas = init.ingredientMadeInAreas.map(d => new IngredientMadeInAreaEntity(d));
    }

    if (init.ingredientAdditives) {
      this.ingredientAdditives = init.ingredientAdditives.map(d => new IngredientAdditiveEntity(d, this));
    }

    if (init.ingredientItems) {
      this.ingredientItems = init.ingredientItems.map(d => new IngredientItemEntity(d, this));
    }

    this.nutrition = new IngredientNutritionEntity(init.nutrition || {});

    this.ingredientDisplaySetting = new IngredientDisplaySetting(init.ingredientDisplaySetting || {});

    if (init.specReference) {
      this.specReference = new IngredientSpecReferenceEntity(init.specReference);
    }

    this.tags = init.tags || [];

    this.departments = init.departments || [];
    this.departmentIds = this.departments.map(d => d.id);

    this.isYieldEnabled = !!init.yieldPercent;
    this.original_hasUnit = !!init.unit;
  }

  public get key() {
    return this.isPreproduct ?
      IngredientEntity.KEY_PREFIX_PREPRODUCT + this.id :
      IngredientEntity.KEY_PREFIX + this.id;
  }

  public get isProduct() { return false; }

  public get isFresh() {
    return this.type === FoodType.Fresh;
  }
  public get isAsProcessed() {
    return this.isPreproduct || this.type === FoodType.Process || this.type === FoodType.Additive;
  }

  public get yieldRate() {
    return (new IProductMixin(this)).yieldRate;
  }
  public get shouldYieldAsConcentrationReduction(): boolean {
    return (new IProductMixin(this)).shouldYieldAsConcentrationReduction;
  }
  public get concentrationRatio(): number {
    return (new IProductMixin(this)).getConcentrationRatio();
  }
  public getRateForIngredientOrder(calcWithReducedWeight: boolean): number {
    return (new IProductMixin(this)).getRateForIngredientOrder(calcWithReducedWeight);
  }
  public getTotalConcentrationRate(forNutrition: boolean): number {
    const ings = this.getIngredientWithAmountRecursiveForNutritionRow(forNutrition);
    const ingCollection = new IngredientWithAmountCollection(ings);
    return ingCollection.getTotalConcentratedAmountRatio();
  }

  public get costPerKgComputed():number|null {
    if (this.isPreproduct) {
      return sumBy(this.ingredientItems, (ii:IngredientItemEntity) => {
        return (ii.costPerKgComputed || 0) * ii.amountRatioInTheIngredient;
      } ) * (1 / this.yieldRate);
    } else {
      if (!this.costPerKg) return null;
      return this.costPerKg * (1 / this.yieldRate);
    }
  }
  public get costSumComputed():number|null {
    if (this.isPreproduct) {
      return sumBy(this.ingredientItems, (i:IngredientItemEntity) => i.costSumComputed || 0);
    } else {
      return null;
    }
  }
  public getNutritionValue(property: string) {
    const nutrition = this.nutrition.getValue(property);
    return NutritionValue.calc([nutrition], _ => nutrition.number!, property, 100 );
  }

  /**
   * @param forNutrition trueなら,中間原材料で栄養成分overrideされてたらそれを返す.
   * @param parentRatio
   * @param parentConcentrationRatio
   */
  public getIngredientWithAmountRecursiveForNutritionRow(forNutrition: boolean, parentRatio: number = 1, parentConcentrationRatio: number = 1): IngredientWithAmount[]
  {
    if (!this.isPreproduct) {
      return [new IngredientWithAmount(this, parentRatio, parentConcentrationRatio)];
    }
    if (forNutrition && this.isNutritionOverrideOnPreproduct) {
      return [new IngredientWithAmount(this, parentRatio, parentConcentrationRatio)];
    }
    const concentrationRatio = parentConcentrationRatio * this.concentrationRatio;
    return this.ingredientItems.flatMap((ii: IngredientItemEntity) => ii.getIngredientWithAmountRecursiveForNutritionRow(forNutrition, parentRatio, concentrationRatio));
  }

  public getAllAllergens(): AllergyEntity[] {
    const ret = this.ingredientItems.flatMap(ii => ii.getAllAllergens() );
    ret.push(...this.ingredientAdditives.flatMap(ia => ia.originAllergens));
    return ret.sort((a,b) => a.order  - b.order);
  }
  public getAllGmos(): IngredientItemGmoEntity[] {
    return this.ingredientItems.flatMap(ii => ii.getAllGmos() );
  }

  public get amountIsPercent() {
    if (this.isPreproduct){
      return false;
    } else if (this.isFresh) {
      return true;
    }

    return this.ingredientDisplaySetting.isIngredientItemAmountPercent;
  }

  // 原材料(中身)と添加物の重量の合計の分母
  // 割合表記の時は100%が分母になる
  public getAmountDenominator(asMilliGram) {
    if (this.amountIsPercent) return 100;

    const ingredientItemsAmountSum = sumBy(this.ingredientItems, ii => ii.amountComputed) * (asMilliGram ? 1000 : 1);
    const ingredientAdditivesAmountSum = sumBy(this.ingredientAdditives, ia => ia.getAmount()) / (asMilliGram ? 1 : 1000);
    return ingredientItemsAmountSum + ingredientAdditivesAmountSum;
  }

  // 原材料(中身)と添加物の重量の合計. 割合または重量の合計
  public getAmountSumRatioOrGram(isPercent = this.amountIsPercent) {
    const ingredientItemsAmountSum = sumBy(this.ingredientItems, ii => ii.amountComputed);

    if (isPercent) {
      return ingredientItemsAmountSum + sumBy(this.ingredientAdditives, ia => ia.getAmount());
    } else {
      return ingredientItemsAmountSum + sumBy(this.ingredientAdditives, ia => ia.getAmount() / 1000);
    }
  }

  public getAmountSumGram(): number {
    if (!this.isPreproduct) throw new Error('invalid operation IngredientEntity@getAmountSumGram');
    return this.getAmountSumRatioOrGram(false);
  }

  public get items() { return this.ingredientItems; }

  // 中間原材料を含めて返す
  public getAllChildren(): IngredientEntity[] {
    return this.items
      .filter(ii => ii.getChildIngredient())
      .flatMap((pi:IngredientItemEntity) => {
        return [pi.getChildIngredient()!].concat(...pi.getChildIngredient()!.getAllChildren());
      });
  }
  public getAllChildrenRecursive(): IRecipeComponent[] {
    return this.getAllChildren();
  }
  // 中間原材料の場合は、中身の原材料を返す
  public getAllChildIngredientsRecursive(): IngredientEntity[] {
    return this.items.filter(ii => !!ii.childIngredient).flatMap((ii:IngredientItemEntity) => {
      if (ii.childIngredient!.isPreproduct) {
        return ii.childIngredient!.getAllChildIngredientsRecursive();
      } else {
        return [ii.childIngredient!].concat(...ii.childIngredient!.getAllChildIngredientsRecursive());
      }
    });
  }
  public getAllChildItemsRecursive(): IngredientItemEntity[] {
    return this.items.flatMap(ii => {
      return [ii].concat(...(ii.getChildIngredient() ? ii.getChildIngredient()!.getAllChildItemsRecursive() : []));
    });
  }
  public getAllIngredientAdditivesRecursive(): IngredientAdditiveEntity[] {
    return this.ingredientAdditives.concat(...this.getAllChildIngredientsRecursive().flatMap(i => i.ingredientAdditives));
  }

  public getParentsRecursive(): Array<IHasParent> {
    if(!this.parentItem) return [];
    return [this.parentItem, ...this.parentItem.getParentsRecursive()];
  }

  public getNameWithDisplayName(): string {
    if (!this.displayName) return this.name!; // 添加物
    return this.name + "（" + this.displayName + "）";
  }

  public findAmountRatioForIngredientOrderInTheRoot(needle:IAmountItem, calcWithReducedWeight: boolean, parentRatio = 1):number|null {
    const needleParentIdList = needle.getParentsRecursive().map(p => p.id);
    if (!needle.isIngredientItem) {
      // 添加物
      const found = this.ingredientAdditives.find((ia:IHasParent) => {
        return ia.id === needle.id &&
          ia.getParentsRecursive().every((haystack:IHasParent) => {
            return needleParentIdList.includes(haystack.id);
          });
      });
      if (found) return found.amountRatioInTheIngredient * parentRatio;
    } else {
      // 原材料内訳
      const found = this.ingredientItems.find((ii:IHasParent) => {
        // iiの全parent_idがneedleの全parent_idと一致するものがあるか
        return ii.id === needle.id &&
          ii.getParentsRecursive().every((haystack:IHasParent) => {
            return needleParentIdList.includes(haystack.id);
          });
      });
      if (!!found) {
        return found.amountRatioInTheIngredient * parentRatio;
      }
    }

    for (let i = 0; i < this.ingredientItems.length; i++) {
      const ii = this.ingredientItems[i];
      if (ii.childIngredient) {
        const ratio = parentRatio * this.getRateForIngredientOrder(calcWithReducedWeight);
        const nested = ii.childIngredient.findAmountRatioForIngredientOrderInTheRoot(needle, calcWithReducedWeight, (ii.amountRatioInTheIngredient || 1) * ratio);
        if (nested !== null) return nested;
      }
    }

    return null;
  }

  public getAllCarryoversRecursive(): CarryOver[] {
    return this.items.flatMap(pi => pi.getChildCarryoversRecursive());
  }
}

export const ValidatorRules = {
  name: [requiredRule, createMaxStringRule() ],
  type: [requiredRule],
  intraCode: [createMaxStringRule()],
  displayName: [requiredRule, createMaxStringRule()],
  costPerKg: [typeNumberOrEmptyRule, createDecimalRule(11, 2), createMinRule(0.01)],
  unit: [createMaxRule(32, 'char')],
  _amountPerUnit: [requiredRule, typeNumberOrEmptyRule, createMaxRule(999999), createMinRule(0.001)],
  yieldPercent: [requiredRule, typeNumberOrEmptyRule, createMaxRule(9999), createMinRule(0.0001), createDecimalRule(8,4)],
  ingredientMadeInAreas: IngredientMadeInAreaEntityValidationRules,
  ingredientAdditives: IngredientAdditiveValidationRules,
  items: IngredientItemValidatorRules,
};
