import {IRecipeComponent} from "@/entities/interfaces/i-recipe-component";

export class IProductMixin {
  constructor(private model:IRecipeComponent) {
  }

  public get yieldRate() { return (this.model.yieldPercent || 100) / 100; }

  public get shouldYieldAsConcentrationReduction(): boolean {
    const model = this.model;
    return model.isYieldEnabled && model.isYieldAffectToNutrition && model.isYieldAsConcentrationReduction && this.yieldRate > 1;
  }

  public getConcentrationRatio():number {
    return (this.model.isYieldEnabled && this.model.isYieldAffectToNutrition)
      ? 1 / this.yieldRate
      : 1;
  }
  // 還元後重量
  private getPostReductionRate(): number {
    if (!this.model.isYieldEnabled || !this.model.isYieldAffectToNutrition) return 1;
    if (this.shouldYieldAsConcentrationReduction) {
      // 還元・水戻しによるもの = 還元後重量 = 配合量
      return 1;
    } else {
      // ただの蒸発・加水 = 還元後重量 = 仕込み量
      return 1 / this.yieldRate;
    }
  }
  public getRateForIngredientOrder(calcWithReducedWeight: boolean): number {
    // 「還元・水戻し後重量で表示順に反映する」がONなら還元後重量を使う。OFFなら濃縮・還元前重量を使う。
    return calcWithReducedWeight ?
      this.getPostReductionRate() :
      this.getConcentrationRatio();
  }
}
