import SpecEntity from "@/entities/specs/spec-entity";
import ObjectUtils from "@/utils/object-utils";
import SpecShareMessage, {
  ISpecShareMessageRequest,
  ValidatorRules as SpecShareMessageValidatorRules
} from "@/entities/specs/spec-share-message-entity";
import {SpecShareEntity} from "@/entities/specs/spec-share-entity";
import { typeNumberRule } from "@/utils/validation-rules";
import {ApprovalRequestEntity, IHasApprovalRequest} from "@/entities/approval-request-entity";

export interface ISpecSubmissionRequest {
  specShareId:number;
  price:number|null;
  message:ISpecShareMessageRequest;
}

export default class SpecSubmissionEntity implements ISpecSubmissionRequest, IHasApprovalRequest {
  public id!:number;
  public specShareId!:number;
  public specShare!:SpecShareEntity;
  public specId!:number;
  public spec!:SpecEntity;
  public price:number|null = null;
  public message!:SpecShareMessage;
  public createdAt!:Date;

  public approvalRequest:ApprovalRequestEntity | null = null;

  constructor(init:Partial<SpecSubmissionEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    if (init.specShare) {
      this.specShare = new SpecShareEntity(init.specShare);
    }

    if (init.spec) {
      this.spec = new SpecEntity(init.spec);
    }
    this.message = new SpecShareMessage(init.message || {});

    if (init.approvalRequest) {
      this.approvalRequest = new ApprovalRequestEntity(init.approvalRequest);
    }
  }
}

export const ValidatorRules:any = {
  price: [typeNumberRule],
  message: SpecShareMessageValidatorRules,
};
