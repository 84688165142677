import IngredientEntity from "@/entities/ingredient-entity";

export class IngredientWithAmount {

  public constructor(
    public ingredient: IngredientEntity,
    public amountRatio: number,
    public concentrationRatioAcc: number
  )
  {
  }

  public getConcentratedAmountRatio(): number
  {
    return this.amountRatio * this.concentrationRatioAcc;
  }
}
