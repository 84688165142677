import ObjectUtils from "@/utils/object-utils";
import {SpecAcceptanceSpecEntity} from "@/entities/specs/spec-acceptance-entity";

export default class IngredientSpecReferenceEntity {
    public id!:number;
    public ingredientId!:number;
    public specAcceptanceSpecId!: number;
    public specAcceptanceSpec!: SpecAcceptanceSpecEntity;

    constructor(init:IngredientSpecReferenceEntity) {
        ObjectUtils.assignLiteralFields(this, init);

        if (init.specAcceptanceSpec) {
          this.specAcceptanceSpec = new SpecAcceptanceSpecEntity(init.specAcceptanceSpec);
        }
    }

}

