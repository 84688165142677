import IngredientAdditiveEntity from "@/entities/ingredient-additive-entity";
import CarryOver from "@/entities/carryover-entity";
import IngredientItemEntity, {IAmountItem} from "@/entities/ingredient-item-entity";
import {FoodType} from "@/entities/specs/spec-entity";
import IngredientEntity from "@/entities/ingredient-entity";
import {IRecipeComponentItem} from "@/entities/interfaces/i-recipe-component";

export interface ICarryoverable {
  id:number,
  isCarryover:boolean;
  reasonType:number|null;
  containsAllergen:boolean;
  hidden: boolean
  disabled: boolean;
  ingredient: IngredientEntity;

  amountItem:IAmountItem;
  rowKey: number;
}

export default class CarryoverHandler {
  public isCarryoverOpened:boolean = false;

  private carryovers:CarryOver[] = [];
  private readonly parent!:IRecipeComponentItem;

  public ingredients:ICarryoverable[] = [];
  public additives:ICarryoverable[] = [];

  public itemGroup:{
    key: 'ingredient' | 'additive',
    type: '原材料' | '添加物',
    isIngredient: boolean,
    items: ICarryoverable[],
  }[] = [];

  constructor(carryovers:CarryOver[], parent:IRecipeComponentItem) {
    this.carryovers = carryovers;
    this.parent = parent;
    this.init();
  }

  public init() {
    const self = this;

    const createItem = (item:IAmountItem, allergies, carryover, prop): ICarryoverable|any => {
      return {
        id: item.id!,
        _isCarryover: !!carryover,
        get isCarryover(){ return this._isCarryover; },
        set isCarryover(val) {
          this._isCarryover = val;
          if (val) {
            self.carryovers.push(new CarryOver({[prop]: this.id}));
          } else {
            const index = self.carryovers.findIndex(c => c[prop] === this.id);
            self.carryovers.splice(index, 1);
          }
        },
        ingredient: item.ingredient,
        reasonType: carryover ? carryover.reasonType : null,
        containsAllergen: allergies.length > 0,
        amountItem: item,
        rowKey: item.rowKey,
        hidden: !item.visible,
        disabled: item.isIngredientItem && (item as IngredientItemEntity).ingredient.type === FoodType.Additive // 製剤の中の食品はdisabled
      };
    };

    this.ingredients = this.getChildIngredientItemsRecursiveWithCarryoverExcluded().map((ii:IngredientItemEntity) => {
      const carryover = this.carryovers.find(c => c.carryoverIngredientItemId === ii.id);
      return createItem(ii, ii.allergies, carryover, "carryoverIngredientItemId");
    });

    this.additives = this.getChildAdditivesRecursiveWithCarryoverExcluded().map(ia => {
      const carryover = this.carryovers.find(c => c.carryoverIngredientAdditiveId === ia.id);
      return createItem(ia, ia.originAllergens, carryover, "carryoverIngredientAdditiveId");
    });

    this.itemGroup = [
      {
        key: 'ingredient',
        type: '原材料',
        isIngredient: true,
        items: this.ingredients,
      },
      {
        key: 'additive',
        type: '添加物',
        isIngredient: false,
        items: this.additives,
      },
    ]
  }

  public get items() { return this.ingredients.concat(...this.additives); }
  public get isAnyChecked() { return this.items.some(c => c.isCarryover); }

  // 全ての子原料のうち、途中でキャリーオーバーされていないものを取得する
  private getChildIngredientItemsRecursiveWithCarryoverExcluded():IngredientItemEntity[] {
    const childCarryoverIngredientIds = this.parent.getChildCarryoversRecursive(false).map(c => c.carryoverIngredientItemId);
    return this.parent.getChildIngredientItemsRecursive().filter(ii => !childCarryoverIngredientIds.includes(ii.id));
  }
  // 全ての子添加物のうち、途中でキャリーオーバーされていないものを取得する
  private getChildAdditivesRecursiveWithCarryoverExcluded():IngredientAdditiveEntity[] {
    const childCarryoverAdditiveIds = this.parent.getChildCarryoversRecursive(false).map(c => c.carryoverIngredientAdditiveId);
    return this.parent.getAdditivesRecursive().filter(ia => !childCarryoverAdditiveIds.includes(ia.id));
  }

  public serialize(): CarryOver[] {
    return this.ingredients.filter(c => c.isCarryover).map(c => {
      return new CarryOver({
        carryoverIngredientItemId: c.id,
        reasonType: c.reasonType
      });
    }).concat(this.additives.filter(c => c.isCarryover).map(c => {
      return new CarryOver({
        carryoverIngredientAdditiveId: c.id,
        reasonType: c.reasonType
      });
    }))
  }

}
