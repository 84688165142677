import ObjectUtils from "@/utils/object-utils";

export default class IngredientDisplaySetting {
  public id!: number| null;

  // 複合原材料の中身・添加物を%表記に
  public isIngredientItemAmountPercent : boolean = true;
  public get isIngredientItemAmountGram() { return !this.isIngredientItemAmountPercent; }
  public set isIngredientItemAmountGram(val) { this.isIngredientItemAmountPercent = !val; }

  // 複合原材料を分割可能に
  public isCompositeCanBeSplitted : boolean = false;

  // 加工食品時に「原材料表示名から内容が明らか」
  public isCompositeHidden : boolean = false;

  constructor(init:Partial<IngredientDisplaySetting> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }
}

