import ObjectUtils from "@/utils/object-utils";

export default class CarryOver {
  public carryoverIngredientItemId:number|null = null;
  public carryoverIngredientAdditiveId:number|null = null;

  public reasonType:CarryoverReason|null = null;

  constructor(init:Partial<CarryOver> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }
}

export enum CarryoverReason {
  Water = 1,
  ProcessHelper = 2,
  NutritionBooster = 3,
  Carryover = 4,
  Other = 9,
}

export const CarryoverReasonDict = {
  [CarryoverReason.Water]: '水',
  [CarryoverReason.ProcessHelper]: '加工助剤',
  [CarryoverReason.NutritionBooster]: '栄養強化',
  [CarryoverReason.Carryover]: 'キャリーオーバー',
  [CarryoverReason.Other]: 'その他'
};
