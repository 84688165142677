import ObjectUtils from "@/utils/object-utils";
import AdditiveEntity, {
  AdditivePurposeSynonymEntity,
  AdditiveSynonymEntity
} from "@/entities/additive-entity";
import {createDecimalRule, createMaxRule, createMinRule, requiredRule, typeNumberRule} from "@/utils/validation-rules";
import IngredientEntity from "@/entities/ingredient-entity";
import AllergyEntity from "@/entities/allergy-entity";
import toNumber from 'lodash/toNumber';
import {IAmountItem} from "@/entities/ingredient-item-entity";
import RowKey from "@/entities/concerns/rowkey";
import {IHasParent} from "@/entities/interfaces/i-recipe-component";

export interface IHasAdditive {
  additiveId:number|null;
  additive:AdditiveEntity|null;
  additiveSynonymId:number|null;
  additiveSynonym:AdditiveSynonymEntity|null;
  additivePurposeSynonymId:number|null;
  additivePurposeSynonym:AdditivePurposeSynonymEntity|null;
  getOriginAllergens():AllergyEntity[];
}

export default class IngredientAdditiveEntity implements IAmountItem, IHasAdditive {
  public id!:number;
  public ingredientId:number|null = null;
  public ingredient:IngredientEntity;

  public amount:number|null = null; //100gあたり

  public additive:AdditiveEntity|null = null;
  public additiveSynonymId:number|null = null;
  public additiveSynonym:AdditiveSynonymEntity|null = null;
  public additivePurposeSynonymId:number|null = null;
  public additivePurposeSynonym:AdditivePurposeSynonymEntity|null = null;
  public originAllergens:AllergyEntity[] = [];
  public originAllergenIds:number[] = [];
  public visible: boolean = true;

  public readonly rowKey:number;
  public $loading = false;

  public get additiveId() {
    return this.additiveSynonym ? this.additiveSynonym.additiveId : null;
  }
  public get isEmpty(): boolean {
    return this.amount === null && !this.additiveSynonymId && this.originAllergenIds.length === 0 && !this.additivePurposeSynonymId;
  }

  constructor(init:Partial<IngredientAdditiveEntity> = {}, parent:IngredientEntity) {
    ObjectUtils.assignLiteralFields(this, init);

    this.ingredient = parent;

    if (init.amount) {
      this.amount = toNumber(this.amount);
    }

    if (init.additive) {
      this.additive = new AdditiveEntity(init.additive);
    }

    if (init.additiveSynonym) {
      this.additiveSynonym = new AdditiveSynonymEntity(init.additiveSynonym);
    }

    if (init.additivePurposeSynonym) {
      this.additivePurposeSynonym = new AdditivePurposeSynonymEntity(init.additivePurposeSynonym);
    }

    if (init.originAllergens) {
      this.originAllergens = init.originAllergens.map(d => new AllergyEntity(d));
      this.originAllergenIds = this.originAllergens.map(i => i.id!);
    }

    this.rowKey = RowKey.getRowKey();
  }

  public get displayName() {
    return this.additiveSynonym ? this.additiveSynonym.synonym : '';
  }
  public getAdditiveDisplayName(): string {
    return this.displayName;
  }
  public getOriginAllergens(): AllergyEntity[] {
    return this.originAllergens;
  }

  public setDisplayName(val) {
    if (this.additiveSynonym) {
      this.additiveSynonym.synonym = val;
    } else {
      this.additive!.name = val;
    }
  }

  public get isIngredientItem():boolean { return false; }

  public get amountRatioInTheIngredient(): number {
    if (!this.ingredient) return this.getAmount();
    return this.getAmount() / this.ingredient.getAmountDenominator(true);
  }

  public getAmount():number {
    return Number(this.amount || 0);
  }

  public getParentsRecursive(): Array<IHasParent> {
    if (!this.ingredient) return [];
    return [this.ingredient, ...this.ingredient.getParentsRecursive()];
  }

}

export const ValidatorRules:any = {
  additiveSynonymId: [requiredRule],
  additivePurposeSynonymId: [],
  originAllergenId: [],
  amount: [requiredRule, typeNumberRule, createDecimalRule(11, 6), createMinRule(0)]
};
