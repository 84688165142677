import ObjectUtils from "@/utils/object-utils";
import SpecShareMessage from "@/entities/specs/spec-share-message-entity";
import SpecSubmissionEntity from "@/entities/specs/spec-submission-entity";
import SpecShareRequestEntity from "@/entities/specs/spec-share-request-entity";
import {PartnerBase} from "@/entities/specs/partner-entity-base";
import {i18n} from "@/bootstraps/locale";

export class SpecShareEntity {
  public id!:number;
  public submitterId!: number;
  public receiverId!: number;

  public status!:SpecShareStatus;
  public specShareMessages!:SpecShareMessage[];

  public request!: SpecShareRequestEntity;

  public submissions:SpecSubmissionEntity[] = [];
  public latestSubmission:SpecSubmissionEntity|null = null;
  public latestSubmissionId:number|null = null;

  // 提出側から見た回収側に設定してある非表示項目
  public hiddenSpecFieldsInReceiverClientBySubmitter: string[]|null = null;

  public submitter: PartnerBase|null = null;

  constructor(init:Partial<SpecShareEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    if(init.specShareMessages) {
      this.specShareMessages = init.specShareMessages.map(m => new SpecShareMessage(m));
    }

    this.submissions = init.submissions ? init.submissions.map(s => new SpecSubmissionEntity(s)) : [];

    if(init.latestSubmission) {
      this.latestSubmission = new SpecSubmissionEntity(init.latestSubmission);
      this.latestSubmissionId = init.latestSubmission.id;
    }

    if(init.request) {
      this.request = new SpecShareRequestEntity(init.request);
    }

    if(init.hiddenSpecFieldsInReceiverClientBySubmitter) {
      this.hiddenSpecFieldsInReceiverClientBySubmitter = init.hiddenSpecFieldsInReceiverClientBySubmitter;
    }

    if (init.submitter) {
      this.submitter = new PartnerBase(init.submitter);
    }
  }

  public get canCreateSubmission() {
    return [SpecShareStatus.REQUESTED, SpecShareStatus.RFC].includes(this.status);
  }

  public get isAccepted(): boolean {
    return this.status === SpecShareStatus.ACCEPTED;
  }
}
export class SpecShareDetailEntity extends SpecShareEntity {
  public clientId!: number | null;
}

export enum SpecShareStatus {
  REQUEST_CANCELED = 'request_canceled',
  REQUEST_DECLINED = 'request_declined',
  REQUESTED = 'requested',
  ACCEPTED = 'accepted',
  RFC = 'rfc',
  SUBMITTED = 'submitted',
}
export enum SpecShareStatusForDisplay {
  All = 'all',
  Aborted = 'aborted',
  TodoForSubmitter = 'todo',

  REQUESTED = 'requested',
  ACCEPTED = 'accepted',
  RFC = 'rfc',
  SUBMITTED = 'submitted',
}
export function convertFromSpecShareForDisplay(status:SpecShareStatusForDisplay|string): SpecShareStatus[] {
  if(status === SpecShareStatusForDisplay.All) return Object.keys(SpecShareStatus).map(k => SpecShareStatus[k]);
  if(status === SpecShareStatusForDisplay.Aborted) return [SpecShareStatus.REQUEST_CANCELED, SpecShareStatus.REQUEST_DECLINED];
  if(status === SpecShareStatusForDisplay.TodoForSubmitter) return [SpecShareStatus.REQUESTED, SpecShareStatus.RFC];
  return [status as SpecShareStatus];
}

export const STATUS_DICT_FOR_RECEIVER = {
  [SpecShareStatus.REQUEST_CANCELED]: i18n.t('中断'),
  [SpecShareStatus.REQUEST_DECLINED]: i18n.t('相手方取下げ'),
  [SpecShareStatus.REQUESTED]: i18n.t('未受領'),
  [SpecShareStatus.ACCEPTED]: i18n.t('回収済'),
  [SpecShareStatus.RFC]: i18n.t('差戻し'),
  [SpecShareStatus.SUBMITTED]: i18n.t('要確認'),
} as {[key: string]: string};


export const STATUS_DICT_FOR_SUBMITTER = {
  [SpecShareStatus.REQUEST_CANCELED]: i18n.t('相手方中断'),
  [SpecShareStatus.REQUEST_DECLINED]: i18n.t('取下げ'),
  [SpecShareStatus.REQUESTED]: i18n.t('要提出'),
  [SpecShareStatus.ACCEPTED]: i18n.t('提出済'),
  [SpecShareStatus.RFC]: i18n.t('要修正'),
  [SpecShareStatus.SUBMITTED]: i18n.t('先方確認中'),
} as {[key: string]: string};
