import IngredientEntity from "@/entities/ingredient-entity";
import {IngredientWithAmount} from "@/entities/concerns/ingredient-with-amount";
import sum from "lodash/sum";

export class IngredientWithAmountCollection {

  public constructor(
    public items: IngredientWithAmount[]
  )
  {
  }


  public getTotalConcentratedAmountRatio(): number {
    return sum(this.items.map(i => i.getConcentratedAmountRatio()));
  }
}
