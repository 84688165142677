import ObjectUtils from "@/utils/object-utils";
import {
  createDecimalRule,
  createMaxRule,
  createMinRule,
  requiredRule,
  typeNumberRule
} from "@/utils/validation-rules";
import AllergyEntity from "@/entities/allergy-entity";
import IngredientEntity from "@/entities/ingredient-entity";

import CarryOverHandler from "@/entities/concerns/carryover-handler";
import IngredientAdditiveEntity from "@/entities/ingredient-additive-entity";
import IngredientItemGmoEntity, {
  GmoEntity,
  ValidatorRules as IngredientItemGmoValidatorRules
} from "@/entities/ingredient-item-gmo-entity";
import CarryOver from "@/entities/carryover-entity";
import RowKey from "@/entities/concerns/rowkey";
import {IngredientWithAmount} from "@/entities/concerns/ingredient-with-amount";
import {
  getAmountGram,
  IHasParent,
  IRecipeComponent,
  IRecipeComponentItem,
  RecipeComponentItemUnitType
} from "@/entities/interfaces/i-recipe-component";


export interface IAmountItem extends IHasParent {
  amount: number|null;
  readonly isIngredientItem: boolean;
  readonly amountRatioInTheIngredient:number;
  rowKey:number;
  visible: boolean;
  ingredient:IngredientEntity;
}

// 構成原材料
export default class IngredientItemEntity implements IRecipeComponentItem, IAmountItem {
  public id!:number;
  public ingredientId:number|null = null;
  public ingredient!:IngredientEntity;
  public name:string|null = '';
  public amount:number|null = null; // gまたは%
  public visible: boolean = true;

  public isCompositeSplitted:boolean|null = null;
  public unitType: RecipeComponentItemUnitType = RecipeComponentItemUnitType.GRAM;

  public childIngredient:IngredientEntity|null = null;
  public childIngredientId:number|null = null;

  public allergies:AllergyEntity[] = [];
  public allergyIds:number[] = [];

  public ingredientItemGmos:IngredientItemGmoEntity[] = [];

  public allergenIdToOmitDisplay:number|null = null;

  public carryoverHandler!:CarryOverHandler;
  public carryovers:CarryOver[] = [];
  public rowKey!:number;

  public isGmoProp:boolean = false;

  constructor(init:Partial<IngredientItemEntity> = {}, parent:IngredientEntity) {
    ObjectUtils.assignLiteralFields(this, init);

    this.ingredient = parent;

    if (init.childIngredientId) {
      this.childIngredient = new IngredientEntity(init.childIngredient || (init as any).childIngredientForList, this);
    }

    if (init.allergies) {
      this.allergies = init.allergies.map(d => new AllergyEntity(d));
      this.allergyIds = this.allergies.map(i => i.id!);
    }

    if (init.ingredientItemGmos) {
      this.ingredientItemGmos = init.ingredientItemGmos.map(d => new IngredientItemGmoEntity(d));
      this.isGmoProp = this.ingredientItemGmos.length > 0;
    }

    if(init.carryovers) {
      this.carryovers = init.carryovers.map(c => new CarryOver(c));
    }

    this.rowKey = RowKey.getRowKey();
    this.carryoverHandler = new CarryOverHandler(this.carryovers || [], this);
  }

  public get label(): string {
    return (this.childIngredient ? this.childIngredient.name : this.name) || '';
  }

  public get isEmpty(): boolean {
    return IngredientItemEntity.isEmptyStatic(this);
  }
  // とりあえず
  public static isEmptyStatic(ii) {
    return ii.amount === null && !ii.name && ii.allergyIds.length === 0 && !ii.isGmoProp;
  }

  public get item() :IngredientEntity|null {
    return this.childIngredient;
  }
  public set item(val :IngredientEntity|null) {
    this.childIngredient = val;
    this.childIngredientId = val ? val.id! : null;
    this.carryoverHandler.init();
  }
  public get propItem() { return this.item; }
  public get key() {
    return this.item ? this.item!.key : null;
  }
  public get parent():IRecipeComponent { return this.ingredient; }

  public get amountComputed():number {
    return getAmountGram(this.unitType, Number(this.amount), this.childIngredient);
  }

  public get amountRatioInTheIngredient():number {
    const denom = this.ingredient.getAmountDenominator(false);
    if (denom === 0) return 0;
    // const yieldRatio = (this.ingredient.isYieldEnabled && this.ingredient.isYieldAffectToNutrition) ? (100 / this.ingredient.yieldPercent) : 1;
    // return (this.amountComputed / denom) * yieldRatio;
    return (this.amountComputed / denom);
  }
  public get amountRatioInTheDirectParent() { return this.amountRatioInTheIngredient; }

  public get isIngredientItem():boolean { return true; }

  private get isPreproductItem(): boolean {
    return this.ingredient.isPreproduct;
  }

  public getChildIngredient(): IngredientEntity | null {
    return this.childIngredient;
  }
  public getChildIngredientItemsRecursive():IngredientItemEntity[] {
    if (this.isPreproductItem) {
      if (!this.childIngredient) return []; // 未設定(中間原材料編集中画面)

      return this.childIngredient.ingredientItems.flatMap(ii => {
        return ii.getChildIngredientItemsRecursive();
      });
    }

    // TODO: 加工食品の内訳が原材料を参照するようになったら、それを処理するようにする
    return [this];
  }
  public getIngredientWithAmountRecursiveForNutritionRow(forNutrition: boolean, parentRatio: number, parentConcentrationRatio: number): IngredientWithAmount[]
  {
    if (!this.childIngredient) return [];
    const amountRatio = parentRatio * this.amountRatioInTheDirectParent;
    return this.childIngredient.getIngredientWithAmountRecursiveForNutritionRow(forNutrition, amountRatio, parentConcentrationRatio);
  }

  public getAdditivesRecursive():IngredientAdditiveEntity[] {
    if (this.isPreproductItem) {
      if (!this.childIngredient) return []; // 未設定(中間原材料編集中画面)

      return this.childIngredient.ingredientItems.flatMap(ii => {
        return ii.getAdditivesRecursive();
      }).concat(...this.childIngredient!.ingredientAdditives);
    }
    return [];

  }

  public getChildCarryoversRecursive(includeSelf = true):CarryOver[] {
    if (!this.childIngredient) return [];

    const concat = includeSelf ? this.carryovers : [];
    return this.childIngredient.ingredientItems.flatMap(ii => {
      return ii.carryovers.concat(...ii.getChildCarryoversRecursive());
    }).concat(...concat);
  }


  public get costPerKgComputed():number|null {
    if (!this.item) return null;
    return this.item!.costPerKgComputed;
  }
  public get costSumComputed():number|null {
    if (!this.ingredient.isPreproduct) throw new Error('invalid operation IngredientItemEntity@costSumComputed');
    if (this.costPerKgComputed === null) return null;
    const amountKg = (this.amountComputed / 1000);
    return this.costPerKgComputed * amountKg;
  }

  public getAllAllergens(): AllergyEntity[] {
    return this.allergies.concat(
      ...(this.childIngredient ? this.childIngredient.getAllAllergens() : [])
    );
  }
  public getAllGmos(): IngredientItemGmoEntity[] {
    return this.ingredientItemGmos.concat(
      ...(this.childIngredient ? this.childIngredient.getAllGmos() : [])
    );
  }

  public getParentsRecursive(): Array<IHasParent> {
    if (!this.parent) return [];
    return [this.parent, ...this.parent.getParentsRecursive()];
  }
}

export const IngredientItemValidatorRules:any = {
  propItem: [requiredRule],
  name: [requiredRule, createMaxRule(255, 'char')],
  amount: [requiredRule, typeNumberRule, createDecimalRule(11,6), createMinRule(0)],
  allergyIds: [],
  gmos: IngredientItemGmoValidatorRules,
};
